import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BlogListComponent } from './blog-list/blog-list.component';
import { BlogPostingListComponent } from './blog-posting-list/blog-posting-list.component';
import { ViewBlogComponent } from './view-blog/view-blog.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SearchPipe} from './pipes/search-pipe';

@NgModule({
  declarations: [
    AppComponent,
    BlogListComponent,
    BlogPostingListComponent,
    ViewBlogComponent,
    SearchPipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
