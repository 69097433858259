import { Injectable } from '@angular/core';
import { BlogPost } from './BlogPost';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from '../../node_modules/rxjs';
import { Blog } from './Blog';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  private webServiceUrl = 'https://dylanhoustonwebservice.azurewebsites.net/api/';

  // private webServiceUrl = 'http://localhost:11439/api/';

  private headers = new HttpHeaders().set('content-type', 'application/json');

  private requestOptions = { withCredentials : true };

  constructor(private http: HttpClient) { }

  getBlogPosts(): Observable<BlogPost[]> {
    const url = this.webServiceUrl + 'blogpost';
    return this.http.get<BlogPost[]>(url);
  }

  getBlogs(): Observable<Blog[]> {
    const url = this.webServiceUrl + 'blog';
    return this.http.get<Blog[]>(url);
  }
}
