import { Pipe, PipeTransform } from '@angular/core';
import { BlogPost } from '../BlogPost';

@Pipe({
  name: 'filterSearch'
})

export class SearchPipe implements PipeTransform {
  transform(items: BlogPost[], searchText: string): any[] {
    if (!items) { return []; }
    if (searchText == null) {
        return items;
    }
    if (!searchText) { return items; }

searchText = searchText.toLowerCase();

return items.filter( it => {
      return it.title.toLowerCase().includes(searchText)
      || it.body.toLowerCase().includes(searchText);
    });
   }
}
