import { Component, OnInit, OnChanges } from '@angular/core';
import { BlogService } from '../blog-service';
import { BlogPost } from '../BlogPost';
import { Blog } from '../Blog';

@Component({
  selector: 'app-blog-posting-list',
  templateUrl: './blog-posting-list.component.html',
  styleUrls: ['./blog-posting-list.component.css']
})
export class BlogPostingListComponent implements OnInit {

  private blogPosts: BlogPost[];

  private blogPostsToShow: BlogPost[];

  private blogs: Blog[];

  public selectedBlog: string;

  private isLoading: boolean;

  constructor(private blogService: BlogService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this.blogService.getBlogPosts()
    .subscribe(data => {
      this.blogPostsToShow = data;
      this.blogPosts = data;
      this.blogService.getBlogs().subscribe(b => {
        this.blogs = b;
        this.isLoading = false;
      }, err => {
        console.log('There was an error in retrieving the blogs.');
        console.log(err);
      });
    }, err => {
      console.log('There was an error in retrieving the blog postings.');
      console.log(err);
    });
  }

  private filterList(blog: string) {
    if (blog != null) {
    this.selectedBlog = blog;
    if (this.selectedBlog !== null && this.selectedBlog !== 'Show all blogs') {
      this.blogPostsToShow = this.blogPosts.filter(x => x.blogId === this.selectedBlog);
    } else {
      this.blogPostsToShow = this.blogPosts;
    }
  }
  }
}
